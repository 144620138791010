// various utils that involve the mapbox map
import bbox from '@turf/bbox'
import { fetchBoundariesIfNeeded } from '../../ducks/boundaries'
import passGLMapEventToFlight from '../../common/utils/passGLMapEventToFlight'
import mapSettings from './mapSettings'

/**
 *  get the features currently rendered on the map under the point
 *
 *  @param  {Object}    map     MapboxGL map object
 *  @param  {Event}     e       Mouse event
 *  @param  {string[]}  layers  Array of layer ids
 */
export const getFeatures = (map, e, layers) => {
  const features = map.queryRenderedFeatures(e.point, { layers })
  return features
}

/** get the hover data for a given point on the map
 * returns an object with title, subtitle, coords array, and visible properties
 * if there are no features under the point, visible is false, title and subtitle are empty strings, and coords are an empty array
 *
 * @param  {Object}    map     MapboxGL map object
 * @param  {Event}     e       Mouse event
 * @param  {string[]}  layers  Array of layer ids
 * @param  {string[]}  displayProperties  Array of properties to display
 */
export const getHoverData = (map, e, layers, displayProperties) => {
  const features = getFeatures(map, e, layers)

  if(features.length === 0) {
    return { title: '', subtitle: '', coords: [], path: null, visible: false }
  }

  const displayFeature = features[0].properties
  const title = displayFeature[displayProperties[0]] || ''
  const subtitle = displayFeature[displayProperties[1]] || ''
  const coords = [e.lngLat.lat, e.lngLat.lng]
  const path = displayFeature?.path || null

  return { title, subtitle, coords, path, visible: true }
}

/**
 *  fit the map to the extent of a boundary
 *
 *  @param  {string}    boundaryPath  path of boundary
 *  @param  {Object}    map         MapboxGL map
 *  @param  {boolean}   animate     Animate transition or not (default true)
 */
export async function zoomToBoundary(boundaryPath, map, animate = true) {
  if(!boundaryPath || !map) return
  const store = window.LL.store
  const activeProjectPresent = store.getState().projects?.active !== null
  if(!activeProjectPresent && boundaryPath !== window.location.pathname) {
    window.history.pushState({}, '', boundaryPath)
  }

  await store.dispatch(fetchBoundariesIfNeeded(boundaryPath))
  const boundary = store.getState().boundaries[boundaryPath]
  const outline = boundary?.outline
  let bounds = bbox(outline.geometry)

  if(boundaryPath === '/us') {
    bounds = mapSettings.commonBounds.usBounds
  }

  map.fitBounds(bounds, {
    padding: 20,
    animate: animate,
  })

  // Behavior change PR #7154, insights/overview is NOT shown to paid users by default
  // const isPaidUser = window.data.has_sitecontrol || false
  // const currentPane = store.getState().mapProperties.currentPane
  // if(currentPane === null && isPaidUser) {
  //   passGLMapEventToFlight('pane:request:show', { tab: '#insights' })
  // } else {
  //   // debug('zoomToBoundary currentPane', currentPane)
  // }

  // pass mapp:regions:switch event Flight
  // this enables the overview pane, history, and a few other things. see sc.js onRegionFocus()
  // this is currently duplicating a couple of redux actions
  passGLMapEventToFlight('mapp:regions:switched', boundary)
}

/**
 * Function to fit the map to the extent of a data source. See regions.js onZoomToExtent()
 *
 * @param {Object} source Regrid source object
 * @param {Object} map MapboxGL map object
 */

export const zoomToSourceExtent = async (source, map) => {
  if(!source) {
    console.error('zoomToSourceExtent: no source was provided.')
    return
  }

  if(!map) {
    console.error('zoomToSourceExtent: no map was provided.')
    return
  }

  if(source.id && source.has_extent) {
    try {
      const response = await fetch(`/sources/${source.id}/extent.json`)
      const data = await response.json()

      const [yMin, xMin, yMax, xMax] = data.source.extent
      const bounds = [xMin, yMin, xMax, yMax]

      map.fitBounds(bounds, {
        padding: 20,
        animate: true,
      })

      //TODO: results.best_path?
    } catch (err) {
      console.error('zoomToSourceExtent error:', err)
    }
  }
}

/**
 * pass in layer and source id Regex patterns and remove from map if there are matches.
 *
 * @param  {Object} map     MapboxGL map
 * @param  {RegExp} layerPattern   Regex pattern for layer id
 * @param  {RegExp} sourcePattern  Regex pattern for source id
 */
export const removeLayersAndSources = (map, layerPattern, sourcePattern) => {
  if(!map || !map.getStyle || typeof map.getStyle !== 'function') {
    throw new Error('Invalid map object.')
  }

  map.on('style.load', () => {
    if(layerPattern) {
      const style = map.getStyle()
      if(style && style.layers) {
        const layerIds = style.layers.map((layer) => layer.id)

        layerIds.forEach((id) => {
          if(layerPattern.test(id)) {
            try {
              map.removeLayer(id)
            } catch (err) {
              console.warn(`Couldn't remove layer ${id}. ${err.message}`)
            }
          }
        })
      } else {
        console.warn('getStyle returned undefined or layers are not available')
      }
    }

    if(sourcePattern) {
      const sourceIds = Object.keys(map.getStyle().sources)

      sourceIds.forEach((id) => {
        if(sourcePattern.test(id)) {
          try {
            map.removeSource(id)
          } catch (err) {
            console.warn(`Couldn't remove source ${id}. ${err.message}`)
          }
        }
      })
    }
  })
}

/**
 * Build a MapboxGL style expression from a set of Regrid style rules
 * https://docs.mapbox.com/style-spec/reference/expressions/
 *
 * @param {Array} rules Array of Regrid style rules objects
 * @param {string} styleProperty The style property (e.g., 'fill', 'line') for which the expression should be generated.
 * @returns {Array} Mapbox style expression array
 */
export const generateMapboxExpression = (rules, styleProperty) => {
  //TODO: error or gracefully handle instance where there are no rules passed in
  const expressions = ['case']

  let hasNonDefaultRules = false
  let defaultStyleValue = null

  for(const rule of rules) {
    //special check for projects originally saved when hex code was used for transparency
    if(rule[styleProperty] === '#FFFFFF00') {
      rule[styleProperty] = 'transparent'
    }

    if(!rule.line) {
      rule.line = 'transparent'
    }

    if(rule.predicate === 'default') {
      defaultStyleValue = rule[styleProperty]
      expressions.push(defaultStyleValue)
    } else {
      hasNonDefaultRules = true

      const condition = []
      if(rule.source && rule.field) {
        // if we want to style user data sources, we check that the source isn't 'parcel'
        // and use rule.source::rule.field as the field name
        // This is due to how tileserver returns vector tiles from data sources
        const field =
          rule.source === 'parcel'
            ? rule.field
            : `${rule.source}::${rule.field}`

        condition.push(['has', field])

        // special columns in surveys, etc. that we need to make sure are numbers
        const specialColumns = ['@status', '@user_id']
        if(specialColumns.includes(rule.field)) {
          rule.value = Number(rule.value)
        }

        switch (rule.predicate) {
          case 'is':
            condition.push(['==', ['get', field], rule.value])
            break
          case 'is not':
            condition.push(['!=', ['get', field], rule.value])
            break
          case 'has any value':
            condition.push(
              ['!=', ['get', field], null],
              ['!=', ['get', field], '']
            )
            break
          case 'lt':
            condition.push(['<', ['get', field], rule.value])
            break
          case 'lte':
            condition.push(['<=', ['get', field], rule.value])
            break
          case 'gt':
            condition.push(['>', ['get', field], rule.value])
            break
          case 'gte':
            condition.push(['>=', ['get', field], rule.value])
            break
          default:
            break
        }

        expressions.push(['all', ...condition], rule[styleProperty])
      }
    }
  }

  // If there are no non-default rules, return a simple 'get' expression
  if(!hasNonDefaultRules && defaultStyleValue) {
    return ['literal', defaultStyleValue]
  }

  // Append a transparent default value if no default predicate was found.
  if(defaultStyleValue === null) {
    expressions.push('transparent') // transparent as the default fallback
  }

  return expressions
}

/**
 * Generate and download a png of the current map view. Dimensions are based on the user's current window dimensions.
 * Note, in the current form, this only works if the map has `preserveDrawingBuffer` set to true, which can also degrade performance.
 *
 * @param {object} map Mapbox map object
 */

export const takeMapScreenshot = (map) => {
  if(!map) {
    console.error('Error: No `map` object passed to the print function')
    return
  }

  const img = map.getCanvas().toDataURL('image/png')
  const a = document.createElement('a')
  a.href = img
  a.download = 'regrid-screenshot.png'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const colorPickerValueToRgba = (colorPickerValue) => {
  const { r, g, b, a } = colorPickerValue

  if(r !== undefined && g !== undefined && b !== undefined && a !== undefined) {
    //defaut rule is transparent and transparency value (a) will stay 0 meaning no color will show
    if( a == 0 && !(r ==0 && g ==0 && b ==0) ){ // eslint-disable-line eqeqeq
      return `rgba(${r}, ${g}, ${b}, 1)`
    }
    return `rgba(${r}, ${g}, ${b}, ${a})`
  }
  return 'rgba(0, 0, 0, 1)' // Default to solid black
}

/**
 * Extracts the country code from the given path.
 *
 * @param {string} path - location path string, e.g., "/us/mi/wayne" or "/us".
 * @returns {string|null} country code if found, otherwise null.
 */
export const countryCodeFromPath = (path) => {
  if(!path) return null
  // Regex to match the country code at the beginning of the path
  const regex = /^\/([a-z]{2})(\/|$)/i
  const match = path.match(regex)
  return match && match[1].toLowerCase()
}

