import { has } from 'lodash'

/** N.B.
 * Chart interaction handlers caused render / state mismatch issues as imported
 * helper functions.
 * - handleMouseEnter(chart, rawKey)
 * - handleMouseLeave(chart)
 * - selectSingleSlice(chart, rawKey)
 */


// --- Pie Chart Utils ---

/**
 * Toggles the visibility of a pie slice in the chart.
 * Also updates the visibility state in the provided state setter.
 * @param {Object} chart - chart instance
 * @param {string} rawKey - key of the slice to toggle
 * @param {Function} setVisibility - state setter for visibility
 */
export const toggleSliceVisibility = (chart, rawKey, setVisibility) => {
  if(chart) {
    const seriesData = chart.series[0]?.data || []
    const chartSlice = seriesData.find((slice) => slice.options.rawKey === rawKey)
    if(chartSlice) {
      const isVisible = chartSlice.visible
      chartSlice.setVisible(!isVisible)
      setVisibility((prev) => ({
        ...prev,
        [rawKey]: !isVisible,
      }))
    }
  }
}


// --- Bar Chart Utils ---

/**
 * Calculates the maximum value of visible points in the provided data.
 * @param {Array} data - array of data points, each with a `visible` property
 * @returns {number} - maximum value of visible points
 */
export const calculateVisibleMax = (data) => {
  const visibleData = data.filter((point) => point.visible)
  const maxValue = Math.max(...visibleData.map((point) => point.y))
  return maxValue
}


/**
 * Toggles the visibility of a bar point in the chart.
 * Also updates the visibility state in the provided state setter.
 * @param {Object} chart - chart instance
 * @param {string} rawKey - key of the bar point to toggle
 * @param {Object} visibility - current visibility state
 * @param {Function} setVisibility - state setter for visibility
 */
export const toggleBarVisibility = (chart, rawKey, visibility, setVisibility) => {
  if(chart) {
    const barData = chart.series[0]?.data || []
    const barPoint = barData.find((p) => p.options.rawKey === rawKey)
    if(barPoint) {
      const isVisible = barPoint.visible
      barPoint.update(
        { visible: !isVisible }
      )

      // Recalculate the maximum value of visible points
      const visibleMax = calculateVisibleMax(barData)
      if(visibleMax !== undefined) {
        chart.yAxis[0].update({ max: visibleMax }, true) // true to redraw
      }

      setVisibility({ ...visibility, [rawKey]: !isVisible })
    }
  }
}

/**
 * Resets the visibility of all bar points in the chart to visible.
 * Also resets the visibility state in the provided state setter.
 * @param {Object} chart - chart instance
 * @param {Function} setVisibility - state setter for visibility
 */
export const resetBarVisibility = (chart, setVisibility) => {
  if(chart) {
    const barData = chart.series[0]?.data || []
    barData.forEach((barPoint) => {
      if(!barPoint.visible) {
        barPoint.update({ visible: true }, false) // false arg prevents redraw
      }
    })

    // Reset visibility state
    setVisibility({})

    // Recalculate the maximum value of visible points
    const visibleMax = calculateVisibleMax(barData)
    if(visibleMax !== undefined) {
      chart.yAxis[0].update({ max: visibleMax }, true) // true to redraw
    }
  }
}

/**
 * Combines owner data from multiple locations into a single sorted object
 * @param {Array} data - array of location objects containing `top_owners`
 * @returns {Object|null} - sorted object of owners with their total counts
 */
export const combineOwnerData = (data) => {
  if(!data) return null
  const owners = {}

  data.forEach((location) => {
    location?.top_owners?.forEach((owner) => {
      const [name, count] = owner
      if(has(owners, name)) {
        owners[name] += count
      } else {
        owners[name] = count
      }
    })
  })

  // Convert the owners object to an array of [name, count] pairs
  const sortedOwnersArray = Object.entries(owners).sort((a, b) => b[1] - a[1])
  // Convert the sorted array back to an object
  const sortedOwners = Object.fromEntries(sortedOwnersArray)
  return sortedOwners
}

/**
 * Finds the path for a specific owner in the provided data.
 * n.b. distinct path from `workingBoundaryPath`, specific to the owner.
 * @param {string} ownerName - name of the owner to find
 * @param {Array} data - array of location objects containing `top_owners`
 * @returns {string|null} - path for the owner, or null if not found
 */
export const findOwnerPath = (ownerName, data) => {
  // Owners by place path, e.g. '/us/mi/wayne/detroit'
  for(const place of data) {
    // Owner array in top_owners, e.g. ['CITY OF DETROIT', 67653, '/us/mi/wayne/detroit']
    for(const owner of place.top_owners) {
      if(owner[0] === ownerName) {
        return owner[2] // Return the path from the found entry
      }
    }
  }
  return null // Return null if name is not found
}



// --- String Formatting ---

/**
 * Formats numeric data into a localized string with headlines.
 * @param {Object} numericData - object containing numeric data to format
 * @param {Object} localeStrings - yaml object containing locale-specific strings
 * @returns {Array|null} - array of formatted objects with headlines and values
 */
export const formatLocaleStats = (numericData, localeStrings) => {
  if(!numericData || !localeStrings) {
    return null
  }

  return Object.keys(numericData).map((key) => {
    let headline = localeStrings[key]
    if(!headline) {
      // Use the key if headline does not exist, replace `_` with space
      // e.g. "counties_online" -> "counties online"
      headline = key.replace(/_/g, ' ')
    } else if(typeof headline === 'object') {
      const isPlural = numericData[key] > 1
      headline = isPlural ? headline.other : headline.one
    }

    let value = numericData[key]
    if(typeof value === 'number' && !Number.isInteger(value)) {
      value = value > 999
        ? numberWithCommas(Math.floor(value))
        : value.toFixed(1)
    }

    return {
      headline: headline,
      value:    value?.toLocaleString(),
    }
  })
}