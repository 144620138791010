import React from 'react'
import PropTypes from 'prop-types'

const WikipediaCard = ({
  wikiData,
  visiblePane,
  headline,
}) => {
  if(visiblePane !== '#insights') return null

  if(!wikiData || !wikiData?.extract) {
    return (
      <div className="insights-failed flex-col-center width-100 height-100">
        <i className="fas fa-exclamation-triangle" />
        <p className="margin-top-lg">Oops! We couldn&apos;t find a Wikipedia page for this place.</p>
      </div>
    )
  }

  const wikiHeadline = headline ?? null

  return (
    <>
      {wikiHeadline && (
        <div className="flex-row-between margin-btm-md">
          <span className="bold margin-none">{wikiHeadline}</span>
          {wikiData.link && (
            <a
              href={wikiData.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Wikipedia{' '}
              <i className="fas fa-external-link-alt margin-left-sm"></i>
            </a>
          )}
        </div>
      )}
      <div className="margin-btm-md">
        <p className="margin-none">{wikiData.extract}</p>
      </div>
    </>
  )
}

WikipediaCard.propTypes = {
  wikiData:    PropTypes.shape({
    extract:   PropTypes.string.isRequired,
    link:      PropTypes.string.isRequired,
  }).isRequired,
  visiblePane: PropTypes.string,
  headline:    PropTypes.string,
}

export default WikipediaCard
