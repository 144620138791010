import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const Column = ({ title, data }) => {
  const categories = Object.keys(data)
  const numbers = Object.values(data)

  const options = {
    chart: {
      animation:           false,
      type:                'column',
      plotBackgroundColor: 'none',
    },
    title: title
      ? {
          text:  title,
          style: {
            fontSize:   '14px',
            fontFamily: 'lato, sans-serif',
            fontWeight: 'bold',
          },
          floating: false,
          align:    'left',
          x:        140,
        }
      : false,
    legend: false,
    xAxis:  {
      categories: categories,
      title:      {
        text:  'Owner',
        align: 'high',
      },
      labels: {
        style: {
          fontFamily: 'lato, sans-serif',
        },
      },
    },
    yAxis: {
      min:   0,
      title: {
        text:  '# of Parcels',
        align: 'high',
        style: {
          fontFamily: 'lato, sans-serif',
        },
      },
      labels: {
        formatter: function () {
          return numberWithCommas(this.value)
        }
      }
    },
    series: [
      {
        name: title,
        data: numbers,
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

Column.propTypes = {
  title: PropTypes.string,
  data:  PropTypes.object.isRequired,
}

export default Column
