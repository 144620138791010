import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getActiveProjectQuery } from 'ducks/projects'
import { isEqual, isEmpty } from 'lodash'
import {
  toggleBarVisibility,
  resetBarVisibility,
  combineOwnerData,
  findOwnerPath
} from './insightsUtils'
import Bar from '../Charts/Bar'
import Pagination from '../Utilities/Pagination'
import FallbackLoadingView from './FallbackLoadingView'

// Top Owner Component
const OwnershipView = ({
  topOwnerData,
  isLoading,
  passEventToFlight
}) => {
  const [chart, setChart] = useState(null)
  const [chartColors, setChartColors] = useState({})
  const [hoveredOwner, setHoveredOwner] = useState(null)
  const [selectedOwner, setSelectedOwner] = useState(null)
  const [highlightedOwner, setHighlightedOwner] = useState(null)
  const [visibility, setVisibility] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const projectQuery = useSelector(getActiveProjectQuery, isEqual)

  useEffect(() => {
    // Reset chartColors when currentPage changes
    if(chart && !isEmpty(chart)) {
      const seriesData = chart.series[0]?.data || []
      const newChartColors = seriesData.reduce((acc, point) => {
        acc[point.name] = point.color
        return acc
      }, {})
      setChartColors(newChartColors)
      resetBarVisibility(chart, setVisibility) // Reset visibility state in react and Highcharts
    }
  }, [currentPage, chart, setVisibility])

  // Fallback or Loading State
  if(isLoading || !topOwnerData || topOwnerData.length === 0) {
    return (
      <FallbackLoadingView id="insights-owners" title="Top Owners" isLoading={isLoading}>
        <p className="small">
          This chart shows the top owners in the currently selected place by number of parcels owned.
        </p>
      </FallbackLoadingView>
    )
  }

  // --- EVENT HANDLERS ---
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  // Chart -> Parent: bar hovered
  const handleBarHover = (rawKey) => {
    setHoveredOwner(rawKey)
  }

  // Chart -> Parent: bar clicked
  const handleBarClick = (rawKey) => {
    if(selectedOwner === rawKey) {
      // Deselect if clicked again
      setSelectedOwner(null)
    } else {
      setSelectedOwner(rawKey)
    }
  }

  // List -> Parent: list item hovered
  const handleMouseEnter = (rawKey) => {
    if(chart) {
      const barData = chart.series[0]?.data || []
      const barPoint = barData.find((p) => p.options.rawKey === rawKey)
      if(barPoint) {
        barPoint.setState('hover')
        // n.b. chart.redraw() breaks hover state on bar
      }
    }
  }

  const handleMouseLeave = () => {
    if(chart) {
      chart.series[0].data.forEach((point) => point.setState('')) // Reset all states
      chart.redraw() // Force redraw of the chart
    }
  }

  const handleToggleVisibility = (rawKey) => {
    if(!chart) return
    toggleBarVisibility(chart, rawKey, visibility, setVisibility)
  }

  const highlightOwnerOnMap = (name) => {
    if(!name || !topOwnerData) {
      return
    }

    const ownerPath = findOwnerPath(name, topOwnerData)

    if(name && name !== highlightedOwner) {
      // Highlight the owner on the map
      const parcelQuery = {}
      if(name && ownerPath) {
        parcelQuery[ownerPath] = { owner: name }
      } else if(name && !ownerPath) {
        // When is this relevant? Parity with LL.StatsBase highlightOwner()
        parcelQuery['owner'] = name
      }

      passEventToFlight('query:change', {
        parcel:          parcelQuery,
        path:            ownerPath,
        operation:       'intersection',
        updateInterface: true,
      })
      // Update the highlighted owner state
      setHighlightedOwner(name)
    } else if(name && name === highlightedOwner) {
      // Clear the highlighted owner state. If in project, restore saved query.

      const originalQuery = projectQuery
        ? projectQuery
        : {
            parcel:          {},
            path:            ownerPath,
            operation:       'intersection',
            updateInterface: true,
          }
      passEventToFlight('query:change', originalQuery)
      setHighlightedOwner(null)
    }
  }

  // Combine owner data from all locations
  const combinedOwnerData =
    topOwnerData?.length > 0 ? combineOwnerData(topOwnerData) : null

  const hasData =
    combinedOwnerData && Object.keys(combinedOwnerData)?.length > 0
  const itemsPerPage = 8
  const totalPages = hasData
    ? Math.ceil(Object.keys(combinedOwnerData).length / itemsPerPage)
    : 0

  // Paginate data
  const currentItems = hasData
    ? Object.entries(combinedOwnerData).slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    : []

  // Convert currentItems back to an object for the Bar chart
  const currentItemsObject = Object.fromEntries(currentItems)

  let selectedColor = '#7cb5ec' // Default Highcharts blue
  if(selectedOwner && chartColors[selectedOwner]) {
    selectedColor = chartColors[selectedOwner]
  }

  // --- RENDER ---
  return (
    <div id="insights-owners" className="flex-col-start">
      <div className="row width-100 sticky-header">
        <div className="col-sm-12">
          <h3>Top Owners</h3>
          <p className="small">
            This chart shows the top owners in the currently selected place by
            number of parcels owned.
          </p>
          <p className="hr"></p>
        </div>
      </div>
      <div className="flex-row-start width-100">
        <div className="row width-100">
          <div className="col-md-12 col-lg-6 col-lg-push-6">
            <div className="ownership-chart">
              <Bar
                id="top-owner-bar"
                className="margin-top-lg"
                title={null}
                data={currentItemsObject}
                onBarHover={handleBarHover}
                onBarClick={handleBarClick}
                setChart={setChart} // stores a reference to the chart
                setChartColors={setChartColors} // stores the colors used
              />
            </div>
            {selectedOwner && (
              <div className="owner-highlight flex-col-start rounded padded">
                <div className="flex-row-between">
                  <h4>Selected Owner</h4>
                  {selectedOwner === highlightedOwner && (
                    <span className="badge plan-color-accent">
                      Highlighted on Map
                    </span>
                  )}
                </div>
                <div className="flex-row-start">
                  <span className="bold">Name</span>
                  <span>
                    {selectedOwner}
                    <i
                      className="fas fa-circle margin-left-md margin-right-md"
                      style={{ color: selectedColor }}
                    ></i>
                  </span>
                </div>
                <div className="flex-row-start">
                  <span className="bold">Count</span>
                  <span className="">
                    {numberWithCommas(combinedOwnerData[selectedOwner])}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-12 col-lg-6 col-lg-pull-6">
            <ul className="ownership-list simple">
              {currentItems.map(([rawKey, count]) => {
                const isHovered = hoveredOwner === rawKey
                const isSelected = selectedOwner === rawKey
                const isVisible = visibility[rawKey] !== false
                let displayColor = '#7cb5ec' // Default Highcharts blue
                if(chartColors[rawKey]) {
                  displayColor = chartColors[rawKey]
                }
                return (
                  <li
                    key={rawKey}
                    className={
                      `flex-row-start` +
                      (isHovered ? ' hovered' : '') +
                      (isSelected ? ' selected' : '')
                    }
                    onMouseEnter={() => handleMouseEnter(rawKey)}
                    onMouseLeave={() => handleMouseLeave(rawKey)}
                  >
                    <div
                      className="flex-row-start"
                      onClick={() => handleBarClick(rawKey)}
                    >
                      <span className="owner-icon">
                        <i
                          className="fas fa-circle margin-left-md margin-right-md"
                          style={{ color: displayColor }}
                        ></i>
                      </span>
                      <span className="owner-name bold small truncate">
                        {rawKey}
                      </span>
                      <span className={`owner-value ${isVisible ? '' : 'strike'}`}>
                        {numberWithCommas(count)}
                      </span>
                      {/* Toggle owner highlight on map */}
                      <button
                        title="Highlight owner on map"
                        className={`btn btn-round ${
                          highlightedOwner === rawKey
                            ? 'btn-primary'
                            : 'btn-inline'
                        } btn-sm owner-query`}
                        onClick={() => highlightOwnerOnMap(rawKey)}
                      >
                        <i className={`fas fa-filter`}></i>
                      </button>
                      {/* Toggle chart */}
                      <button
                        title="Toggle visibility in chart"
                        className="btn btn-round btn-inline btn-sm owner-toggle"
                        onClick={() => handleToggleVisibility(rawKey)}
                      >
                        <i className={`fas ${isVisible ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                      </button>
                    </div>
                  </li>
                )
              })}
            </ul>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

OwnershipView.propTypes = {
  topOwnerTitle:     PropTypes.string,
  topOwnerData:      PropTypes.array,
  isLoading:         PropTypes.bool,
  passEventToFlight: PropTypes.func.isRequired,
}

export default OwnershipView