import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const Bar = ({
  id,
  className,
  title,
  data,
  onBarHover,     // callback prop to highlight list item on bar hover
  onBarClick,     // callback prop for bar clicks
  setChart,       // callback to store chart instance
  setChartColors, // callback to store chart colors
}) => {
  // Transform object { key: value } -> Highcharts format
  const formatDataForBar = (dataObj) => {
    return Object.entries(dataObj).map(([rawKey, count]) => ({
      rawKey,       // used internally to match keys
      name: rawKey, // label
      y:    count,
    }))
  }

  const barData = formatDataForBar(data)

  const options = {
    chart: {
      type:                'bar',
      plotBackgroundColor: 'none',
      animation:           true,
      events:              {
        load() {
          if(setChart) setChart(this) // expose chart instance to parent
          if(setChartColors) {
            const colors = this.series[0].data.reduce((acc, d) => {
              acc[d.name] = d.color
              return acc
            }, {})
            setChartColors(colors)
          }
        },
      },
    },
    title: title
      ? {
          text:  title,
          style: {
            fontSize:   '14px',
            fontFamily: 'lato, sans-serif',
            fontWeight: 'bold',
          },
          align: 'center',
        }
      : false,
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: barData.map((d) => d.name.substring(0, 4) + '...'),
      title:      {
        text: 'Owner',
      },
      labels: {
        style: {
          fontFamily: 'lato, sans-serif',
        },
      },
    },
    yAxis: {
      min:   0, // or 1?
      title: {
        text:  '# of Parcels',
        style: {
          fontFamily: 'lato, sans-serif',
        },
      },
      labels: {
        formatter: function () {
          return numberWithCommas(this.value)
        },
      },
    },
    plotOptions: {
      series: {
        animation:  true,
        dataLabels: {
          enabled: true, // Disable data labels by default
          style:   {
            fontSize:   '12px',
            fontFamily: 'lato, sans-serif',
          },
          align:     'center',
          overflow:  'justify', // Prevent overflow
          x:         30, // Shift label right by 30px
          formatter: function () {
            return numberWithCommas(this.y)
          },
        },
      },
      bar: {
        cursor: 'pointer',
        states: {
          hover: {
            enabled:     true,
            brightness:  0.1,
            borderColor: 'black',
            borderWidth: 2,
            dataLabels:  {
              enabled: true, // Enable data labels on hover only
            },
          },
          inactive: {
            enabled: true, // greyed-out other bars
          },
        },
        point: {
          events: {
            mouseOver() {
              if(onBarHover) onBarHover(this.options.rawKey)
            },
            mouseOut() {
              if(onBarHover) onBarHover(null)
            },
            click() {
              if(onBarClick) onBarClick(this.options.rawKey)
            },
            select() {
              // debug(`Bar selected: ${this.options.rawKey}, this:`, this)
            },
            unselect() {
              // debug(`Bar unselected: ${this.options.rawKey}, this:`, this)
            },
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        // Show the name + count in the tooltip
        return `<strong>${this.point.name}</strong>: ${numberWithCommas(
          this.point.y
        )}`
      },
      style: {
        fontSize:   '14px',
        fontFamily: 'lato, sans-serif',
      }
    },
    series: [
      {
        name:         title || '',
        data:         barData,
        colorByPoint: true, // apply different colors automatically
        animation:    true,
      },
    ],
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ id, className }}
    />
  )
}

Bar.propTypes = {
  id:             PropTypes.string.isRequired,
  className:      PropTypes.string,
  title:          PropTypes.string,
  data:           PropTypes.object.isRequired,
  onBarHover:     PropTypes.func,
  onBarClick:     PropTypes.func,
  setChart:       PropTypes.func,
  setChartColors: PropTypes.func,
}

export default Bar
