import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const AskChatGptCard = ({workingBoundaryPath, visiblePane }) => {
  const [gptData, setGptData] = useState({})
  const [questionKey, setQuestionKey] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const isPaidUser = window.data.has_sitecontrol ?? false

  useEffect(() => {
    if(!workingBoundaryPath || visiblePane !== '#insights') return
    const path = workingBoundaryPath
    const isDefaultQuestion = questionKey?.length > 0 ? false : true
    const url =
      path +
      '/ask_chatgpt?' +
      (isDefaultQuestion ? '' : '&question_key=' + questionKey)

    const fetchChatGptData = async (url) => {
      setIsLoading(true)
      setError(null)
      LL.Ajax.getSpin(url)
        .then(function (res) {
          // debug('Fetched ChatGPT response:', res)
          setGptData(res)
          setIsLoading(false)
        })
        .catch(function (err) {
          debug('Error fetching ChatGPT data:', err)
          setError('Oops! We couldn\'t load ChatGPT data. Please try again.')
          setIsLoading(false)
        })
    }

    fetchChatGptData(url)
  }, [workingBoundaryPath, visiblePane, questionKey])

  const handleQuestionChange = (e) => {
    const key = e.target.value
    setQuestionKey(key)
  }

  return (
    <div>
      {isLoading ? (
        <div className="insights-loading flex-col-center width-100 height-100">
          <i className="fas fa-spinner fa-spin" />
          <p className="margin-top-lg">Loading AI Data...</p>
        </div>
      ) : error ? (
        <div className="insights-failed flex-col-center width-100 height-100">
          <i className="fas fa-exclamation-triangle" />
          <p className="margin-top-lg">{error}</p>
        </div>
      ) : (
        <div>
          <div>
            <p className="bold">{gptData?.placeQuestions[gptData?.questionKey]}</p>
            <p>{gptData?.answer}</p>
          </div>
          <div className="margin-top-lg">
            <label htmlFor="ai-question" className='hide'>
              {isPaidUser
                ? 'Ask something else'
                : 'Ask more questions with a Pro account'
              }
            </label>

            <select
              id="ai-question"
              className="form-control margin-btm-md"
              value={questionKey}
              onChange={handleQuestionChange}
              // disabled={!isPaidUser}
            >
              <option value="" disabled>
                Ask something else
              </option>
              {Object.entries(gptData?.placeQuestions).map(
                ([key, question]) => (
                  <option key={key} value={key} disabled={!isPaidUser}>
                    {question}
                  </option>
                )
              )}
            </select>
            {!isPaidUser && (
              <a
                id="ask-ai-upgrade"
                href="/plans"
                target="llext"
                className="margin-left-sm"
              >
                Upgrade to Pro to ask more questions about this place.
                <i className="fa fa-external-link margin-left-sm"></i>
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

AskChatGptCard.propTypes = {
  workingBoundaryPath: PropTypes.string,
  visiblePane:         PropTypes.string,
}

export default AskChatGptCard