/* Insights tab labels */
export const activeViewLabels = {
  general: 'General Info',
  landUse: 'Land Use & Zoning',
  owner:   'Ownership',
  vacancy: 'Vacancy',
}

/* Insights endpoint request params by activeView, see PlacesController#insights */
export const activeViewParams = {
  general: ['numeric', 'nativeLand', 'wikipedia'],
  landUse: ['lbcs'],
  owner:   ['owner'],
  vacancy: ['premium'],
}

/**
 * Rules for insights views based on the working boundary and user type.
 * Uses the same keys as activeViewLabels for iteration on render.
 * Correspond to `accessFlags` in the InsightsPane component.
 */
export const viewAccessRules = {
  general: {
    requiresPaid:            false,
    requiresUSA:             false,
    excludesStateOrProvince: false,
    excludesCountryOrWorld:  false,
  },
  owner: {
    requiresPaid:            false,
    requiresUSA:             true,
    excludesStateOrProvince: true,
    excludesCountryOrWorld:  true,
  },
  landUse: {
    requiresPaid:            true,
    requiresUSA:             true,
    excludesStateOrProvince: true,
    excludesCountryOrWorld:  true,
  },
  vacancy: {
    requiresPaid:            true,
    requiresUSA:             true,
    excludesStateOrProvince: true,
    excludesCountryOrWorld:  true,
  },
  'Place Not Available': {
    requiresPaid:            false,
    requiresUSA:             false,
    excludesStateOrProvince: false,
    excludesCountryOrWorld:  false,
  },
}

/* LBCS labels by code, e.g. residential => 1000s, trade => 2000s, etc. */
export const landUseLabels = {
  unknown:        'Unknown',
  residential:    'Residential',
  trade:          'Shopping, business, and trade',
  industrial:     'Industrial, manufacturing, and waste',
  infrastructure: 'Social, institutional, and infrastructure',
  travel:         'Travel and movement',
  assembly:       'Mass assembly of people',
  leisure:        'Leisure',
  natural:        'Natural resources-related',
  vacant:         'Vacant parcel',
}

/* LBCS colors */
export const landUseColors = {
  unknown:        '#CCCCCC',  // #CCCCCC  Mid gray
  residential:    '#FFDF99',  // #FFDF99  More saturated yellow-orange
  trade:          '#FF7F7F',  // #FF7F7F  Brighter red-pink
  industrial:     '#9E6BC0',  // #9E6BC0  Deeper purple
  infrastructure: '#66B2F2',  // #66B2F2  Richer light-blue
  travel:         '#339AF0',  // #339AF0  Stronger blue
  assembly:       '#FFBB80',  // #FFBB80  Saturated peach/orange
  leisure:        '#80D980',  // #80D980  Vibrant green
  natural:        '#52B752',  // #52B752  Deeper green
  vacant:         '#F2F2F2',  // #E8D8C3  Tan/sand
}

/* USPS stats labels */
export const uspsStatusLabels = {
  vacantResidential:      'Vacant Residential',
  occupiedResidential:    'Occupied Residential',
  vacantNonResidential:   'Vacant Non-Residential',
  occupiedNonResidential: 'Occupied Non-Residential',
  unknownStatus:          'Unknown Status',
}
/* USPS status colors */
export const uspsStatusColors = {
  'Vacant Residential':       '#e88471', // #e88471
  'Occupied Residential':     '#39b185', // #39b185
  'Vacant Non-Residential':   '#eeb479', // #eeb479
  'Occupied Non-Residential': '#9ccb86', // #9ccb86
  'Unknown Status':           '#cccccc', // #cccccc
}