import React from 'react'
import PropTypes from 'prop-types'

const NativeLandCard = ({ nativeLandData }) => {
  const hasNativeLandData = nativeLandData && nativeLandData.length > 0

  // const renderItem = (item) => (
  //   <span key={item.id}>
  //     <a href={item.link} target="_llext">
  //       {item.headline}
  //     </a>
  //   </span>
  // )

  // const commaList = (items, renderItem) => {
  //   return items
  //     .map((item, idx) => {
  //       const content = renderItem(item)
  //       if(idx === items.length - 1) {
  //         return content
  //       } else if(idx === items.length - 2) {
  //         return [content, ' and ']
  //       } else {
  //         return [content, ', ']
  //       }
  //     })
  //     .flat()
  // }

  if(!hasNativeLandData) {
    return null
  }

  return (
    <div id="native-land-info" className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">Native Land</h3>
      </div>
      <div className="panel-body">
        {/* <p>
          This place is on the traditional lands of the{' '}
          {commaList(nativeLandData, renderItem)}.
        </p> */}
        <p>This place is on the traditional lands of:</p>
        <ul>
          {nativeLandData.map((item, idx) => (
            <li key={idx} className="margin-bottom-sm">
              <a
                id={`native-land-link-${item.id}`}
                className="native-land-link"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.headline}{' '}
                <i
                  className="fas fa-external-link-alt"
                  aria-hidden="true"
                ></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

NativeLandCard.propTypes = {
  nativeLandData: PropTypes.array,
}

export default NativeLandCard