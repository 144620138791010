import React from 'react'
import PropTypes from 'prop-types'

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const getPageNumbers = () => {
    const pageNumbers = []
    const maxPagesToShow = 5
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2)

    if(totalPages <= maxPagesToShow) {
      for(let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i)
      }
    } else {
      if(currentPage <= halfMaxPagesToShow + 1) {
        for(let i = 1; i <= maxPagesToShow - 1; i++) {
          pageNumbers.push(i)
        }
        pageNumbers.push('...')
        pageNumbers.push(totalPages)
      } else if(currentPage >= totalPages - halfMaxPagesToShow) {
        pageNumbers.push(1)
        pageNumbers.push('...')
        for(let i = totalPages - (maxPagesToShow - 2); i <= totalPages; i++) {
          pageNumbers.push(i)
        }
      } else {
        pageNumbers.push(1)
        if(currentPage - halfMaxPagesToShow > 2) {
          pageNumbers.push('...')
        }
        for(
          let i = currentPage - halfMaxPagesToShow;
          i <= currentPage + halfMaxPagesToShow;
          i++
        ) {
          pageNumbers.push(i)
        }
        if(currentPage + halfMaxPagesToShow < totalPages - 1) {
          pageNumbers.push('...')
        }
        pageNumbers.push(totalPages)
      }
    }

    return pageNumbers
  }

  const pageNumbers = getPageNumbers()

  return (
    <nav className="centered" aria-label="Page navigation">
      <ul className="pagination">
        <li className={currentPage === 1 ? 'disabled' : ''}>
          <a
            href="#"
            aria-label="Previous"
            onClick={() => onPageChange(currentPage - 1)}
          >
            <span className="margin-right-none" aria-hidden="true">
              &laquo;
            </span>
          </a>
        </li>
        {pageNumbers.map((page, index) => (
          <li key={index} className={page === currentPage ? 'active' : ''}>
            {page === '...' ? (
              <a
                href="#"
                className="disabled"
                tabIndex="-1"
                aria-disabled="true"
              >
                ...
              </a>
            ) : (
              <a href="#" onClick={() => onPageChange(page)}>
                {page}
              </a>
            )}
          </li>
        ))}
        <li className={currentPage === totalPages ? 'disabled' : ''}>
          <a
            href="#"
            aria-label="Next"
            onClick={() => onPageChange(currentPage + 1)}
          >
            <span className="margin-right-none" aria-hidden="true">
              &raquo;
            </span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

Pagination.propTypes = {
  totalPages:   PropTypes.number.isRequired,
  currentPage:  PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

export default Pagination
