import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Pie from '../Charts/Pie'
import FallbackLoadingView from './FallbackLoadingView'
import { uspsStatusLabels, uspsStatusColors } from './insightsSettings'
import { toggleSliceVisibility } from './insightsUtils'

const VacancyView = ({ vacancyData, isLoading }) => {
  const [chart, setChart] = useState(null)
  const [hoveredSlice, setHoveredSlice] = useState(null)
  const [selectedSlice, setSelectedSlice] = useState(null)
  const [visibility, setVisibility] = useState({})

  // Fallback or Loading State
  if(
    isLoading ||
    !vacancyData ||
    Object.keys(vacancyData)?.length === 0 ||
    !uspsStatusLabels ||
    !uspsStatusColors
  ) {
    return (
      <FallbackLoadingView id="insights-vacancy" title="USPS Vacancy Status" isLoading={isLoading}>
        <p className="small">
          The following chart shows the distribution of USPS vacancy status
          within your currently selected place.
          <a
            className="margin-left-sm"
            href="https://support.regrid.com/property-app/usps-data"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about our USPS vacancy data
            <i className="fas fa-external-link-alt margin-left-sm"></i>
          </a>
          .
        </p>
      </FallbackLoadingView>
    )
  }

  // --- EVENT HANDLERS ---
  const handleMouseEnter = (rawKey) => {
    if(chart) {
      const seriesData = chart.series[0]?.data || []
      const chartSlice = seriesData.find(
        (slice) => slice.options.rawKey === rawKey
      )
      if(chartSlice) {
        chartSlice.setState('hover')
        chart.redraw() // Force a redraw to apply the state change
      }
    }
  }

  const handleMouseLeave = () => {
    if(chart) {
      chart.series[0].data.forEach((slice) => slice.setState('')) // Reset all states
      chart.redraw() // Force redraw of the chart
    }
  }

  const selectSingleSlice = (rawKey) => {
    if(chart) {
      const seriesData = chart.series[0]?.data || []
      const chartSlice = seriesData.find(
        (slice) => slice.options.rawKey === rawKey
      )

      if(chartSlice) {
        const isSliced = chartSlice.sliced
        chartSlice.select(null, false) // Toggle select and unselect any previously selected slices
        chartSlice.setSlice(!isSliced) // Toggle slice and keep other slices sliced
      }
    }
  }

  const handleSliceClick = (rawKey) => {
    // debug('Handler - Clicked slice:', rawKey)
    if(selectedSlice === rawKey) {
      setSelectedSlice(null)
      selectSingleSlice(rawKey) // chart internal state can toggle
    } else {
      setSelectedSlice(rawKey) // component state
      selectSingleSlice(rawKey) // chart internal state
    }
  }

  const handleSliceHover = (rawKey) => {
    // debug('Handler - Hovered slice key:', rawKey)
    setHoveredSlice(rawKey)
  }

  const handleToggleVisibility = (rawKey) => {
    toggleSliceVisibility(chart, rawKey, setVisibility)
  }

  const selectedSliceData = vacancyData[selectedSlice]
  const selectedSliceLabel = uspsStatusLabels?.[selectedSlice] ?? selectedSlice
  const selectedSliceColor = uspsStatusColors?.[selectedSlice] ?? 'black'
  const selectedSlicePercent = (
    (selectedSliceData /
      Object.values(vacancyData).reduce((acc, cur) => acc + cur, 0)) *
    100
  ).toFixed(0)
  const selectedSlicePercentDisplay =
    selectedSliceData && selectedSlicePercent === '0'
      ? '<1%'
      : `${selectedSlicePercent}%`

  // --- RENDER ---
  return (
    <div id="insights-vacancy" className="flex-col-start">
      <div className="row width-100 sticky-header">
        <div className="col-sm-12">
          <h3>USPS Vacancy Status</h3>
          <p className="small">
            The following chart shows the distribution of USPS vacancy status
            within your currently selected place.
            <a
              className="margin-left-sm"
              href="https://support.regrid.com/property-app/usps-data"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about our USPS vacancy data
              <i className="fas fa-external-link-alt margin-left-sm"></i>
            </a>
            .
          </p>
          <p className="hr"></p>
        </div>
      </div>
      <div className="flex-row-start width-100">
        <div className="row width-100">
          <div className="col-md-12 col-lg-6 col-lg-push-6">
            <div className="land-use-chart">
              <Pie
                title="USPS Vacancy Status"
                data={vacancyData}
                labelsByKey={uspsStatusLabels}
                colorsByKey={uspsStatusColors}
                onSliceHover={handleSliceHover}
                onSliceClick={handleSliceClick}
                setChart={setChart}
              />
            </div>
            {selectedSlice && (
              <div className="land-use-highlight flex-col-start">
                <h4>Selected Status</h4>
                <div className="flex-row-start">
                  <span className="bold">Name</span>
                  <span className="">
                    {selectedSliceLabel}
                    <i
                      className="fas fa-circle margin-left-md margin-right-md"
                      style={{ color: selectedSliceColor }}
                    ></i>
                  </span>
                </div>
                <div className="flex-row-start">
                  <span className="bold">Count</span>
                  <span className="">{selectedSliceData.toLocaleString()}</span>
                </div>
                {selectedSlicePercentDisplay && (
                  <div className="flex-row-start">
                    <span className="bold">Percentage</span>
                    <span className="">{selectedSlicePercentDisplay}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="col-md-12 col-lg-6 col-lg-pull-6">
            <ul className="land-use-list simple">
              {Object.entries(vacancyData)
                .sort((a, b) => b[1] - a[1]) // Sort by value descending
                .map(([rawKey, count]) => {
                  const isHovered = rawKey === hoveredSlice
                  const isSelected = rawKey === selectedSlice
                  const displayName = uspsStatusLabels?.[rawKey] ?? rawKey
                  const displayColor = uspsStatusColors?.[rawKey] ?? 'black'
                  const isVisible = visibility[rawKey] !== false
                  return (
                    <li
                      key={rawKey}
                      onMouseEnter={() => handleMouseEnter(rawKey)}
                      onMouseLeave={() => handleMouseLeave(rawKey)}
                      className={
                        `flex-row-start` +
                        (isHovered ? ' hovered' : '') +
                        (isSelected ? ' selected' : '')
                      }
                    >
                      <div
                        className="flex-row-start"
                        onClick={() => handleSliceClick(rawKey)}
                      >
                        <span className="land-use-icon">
                          <i
                            className="fas fa-circle margin-left-md margin-right-md"
                            style={{ color: displayColor }}
                          ></i>
                        </span>
                        <span className="land-use-name bold">
                          {displayName}
                        </span>
                        <span
                          className={`land-use-value ${
                            isVisible ? '' : 'strike'
                          }`}
                        >
                          {count.toLocaleString()}
                        </span>
                      </div>
                      <button
                        title="Toggle visibility in chart"
                        className="btn btn-round btn-inline btn-sm land-use-toggle"
                        onClick={() => handleToggleVisibility(rawKey)}
                      >
                        <i
                          className={`fas ${
                            isVisible ? 'fa-eye' : 'fa-eye-slash'
                          }`}
                        ></i>
                      </button>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

VacancyView.propTypes = {
  vacancyData: PropTypes.object,
  isLoading:   PropTypes.bool,
}

export default VacancyView