import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const Pie = ({
  title,
  data,
  labelsByKey,
  colorsByKey,
  onSliceHover, // callback prop to highlight list item on slice hover
  onSliceClick, // callback prop for slice clicks
  setChart,     // callback to store chart instance
  hideZeros = true,
}) => {
  // Transform the data into Highcharts-compatible objects
  const formatDataForPie = (dataObj) => {
    return (
      Object.entries(dataObj)
        // Optionally filter out zero values
        .filter(([_key, value]) => {
          if(hideZeros) return value !== 0
          return true
        })
        .sort((a, b) => b[1] - a[1]) // Sort by value descending
        .map(([key, value]) => ({
          rawKey: key, // used for internal lookups and event callbacks
          name:   labelsByKey?.[key] ?? key, // label
          y:      value,
          color:  colorsByKey?.[key] ?? undefined, // color
        }))
    )
  }

  const chartData = formatDataForPie(data)

  const options = {
    chart: {
      type:       'pie',
      height:     300,
      plotShadow: false,
      animation:  true,
      events:     {
        load: function () {
          setChart(this)
        },
      },
    },
    title: title
      ? {
          text:  title,
          style: {
            fontSize:   '16px',
            fontFamily: 'lato, sans-serif',
            fontWeight: 'bold',
          },
          align: 'center',
        }
      : false,
    tooltip: {
      formatter: function () {
        // Show the name + count in the tooltip
        return `<strong>${this.point.name}</strong>: ${numberWithCommas(
          this.point.y
        )}`
      },
      style: {
        fontSize:   '14px',
        fontFamily: 'lato, sans-serif',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor:           'pointer',
        showInLegend:     false,
        dataLabels:       { enabled: false }, // Disable default data labels
        states:           {
          hover: {
            enabled:    true,
            brightness: 0.1, // default is 0.1
            halo:       {
              size:       16, // how large the outer halo should be
              attributes: {
                'stroke-width': 2,
                stroke:         'black',
              },
            },
          },
          inactive: {
            enabled: true, // greyed-out pie slices
          },
        },
        point: {
          events: {
            mouseOver: function () {
              // Pass the raw key back to the parent
              if(onSliceHover) onSliceHover(this.options.rawKey)
            },
            mouseOut: function () {
              if(onSliceHover) onSliceHover(null)
            },
            click: function () {
              // debug(`Slice clicked: ${this.options.rawKey}, this:`, this)
              if(onSliceClick) onSliceClick(this.options.rawKey)
            },
            select: function () {
              // debug(`Slice selected: ${this.options.rawKey}, this:`, this)
            },
            unselect: function () {
              // debug(`Slice UNselected: ${this.options.rawKey}, this:`, this)
            },
          },
        },
      },
    },
    series: [
      {
        name:      title || '',
        data:      chartData,
        animation: true,
      },
    ],
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

Pie.propTypes = {
  title:        PropTypes.string,
  data:         PropTypes.object.isRequired,
  labelsByKey:  PropTypes.object,
  colorsByKey:  PropTypes.object,
  onSliceHover: PropTypes.func,
  onSliceClick: PropTypes.func,
  setChart:     PropTypes.func.isRequired,
  hideZeros:    PropTypes.bool,
}

export default Pie