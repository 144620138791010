import { get } from './api'
import { has } from 'lodash'

// Actions
import {
  RECEIVE_BOUNDARIES,
  REQUEST_BOUNDARIES,
  UPDATE_WORKING_BOUNDARY,
  UPDATE_CURRENT_MAP_BOUNDARY,
  UPDATE_BOUNDARY_STYLE,
  UPDATE_BOUNDARY_CHILDREN,
} from './actions'

// TODO: I shouldn't have to export this to test it, but I can't get rewire
// working with create-react-app.
export const normalize = (json) => {
  // Remove leading path slashes (for easier linking)
  // json.parent = json.parent.substring(1)

  // Convert the list of boundary types into a nice list
  const types = []
  for(const key in json.styles) {
    types.push({
      key:  key,
      name: json.styles[key],
    })
  }
  json.types = types

  return json
}

// API
export const fetchBoundaries = (placePath) => async (dispatch) => {
  if(!placePath) return
  const start = () => {
    dispatch({
      type:      REQUEST_BOUNDARIES,
      placePath: placePath,
    })
  }

  const done = (json) => {
    const normalizedData = normalize(json)
    dispatch({
      type:      RECEIVE_BOUNDARIES,
      data:      normalizedData,
      placePath: placePath,
    })
    dispatch({
      type:      UPDATE_WORKING_BOUNDARY,
      placePath: placePath,
    })
    // expose the response.
    return normalizedData
  }

  const response = await get(`${placePath}/boundaries.json`, { start, done })
  return response
}

// Given a list of boundary types, pick the best one to use
export const getDefaultBoundaryType = (boundaryTypes) => {
  const keys = boundaryTypes.map((b) => b.key)
  const hierarchy = ['admin', 'neighborhoods', 'blocks']
  for(let i = 0; i < hierarchy.length; i++) {
    if(keys.indexOf(hierarchy[i]) !== -1) {
      return boundaryTypes[keys.indexOf(hierarchy[i])]
    }
  }
  return { key: 'none' }
}

export const fetchBoundariesIfNeeded =
  (placePath) => async (dispatch, getState) => {
    const boundaries = getState().boundaries
    if(!boundaries[placePath]) {
      try {
        await dispatch(fetchBoundaries(placePath))
        // Access the updated boundaries state
        // const updatedBoundaries = getState().boundaries;
        // return updatedBoundaries
      } catch (error) {
        console.error('fetchBoundariesIfNeeded error:', error)
      }
    } else {
      dispatch({
        type:      UPDATE_WORKING_BOUNDARY,
        placePath: placePath,
      })
      return boundaries[placePath]
    }
  }

// API
export const fetchBoundaryChildren = (placePath, style) => async (dispatch) => {

  const done = (json) => {
    const normalizedData = normalize(json)

    dispatch({
      type:      UPDATE_BOUNDARY_CHILDREN,
      style:     style,
      placePath: placePath,
      data:      normalizedData.children[style],
    })

    // expose the response.
    return normalizedData
  }

  const fail = (err) => {
    console.error(
      'Error: There was a problem fetching boundary children: ',
      err
    )
  }
  const url = `${placePath}/children.json?style=${style}`
  const response = await get(url, { done, fail })
  return response
}

export const updateBoundaryStyle =
  (placePath, style) => async (dispatch, getState) => {
    // look at setStyle in assets/javascript/regions.js as reference
    let currentStyle
    // check to see if requested style is already in redux
    const boundaries = getState().boundaries
    const currentBoundary = boundaries[placePath]
    const children = currentBoundary?.children
    const boundaryStyles = currentBoundary?.styles ?? {}
    const availableStyles = Object.keys(boundaryStyles)
    if(boundaryStyles[style]) {
      currentStyle = style
    } else if(availableStyles.length > 0) {
      const suggestions = ['admin', 'neighborhoods', 'council_districts']
      currentStyle = suggestions.find((s) => boundaryStyles[s]) || 'none'
    } else {
      currentStyle = 'none'
    }

    // Always show admin on US if none was selected before
    if(placePath === '/us' && currentStyle === 'none') currentStyle = 'admin'

    // in regions.js this is where mapp:regions:style:changed is fired
    // handle if 'none' is set
    if(currentStyle === 'none') {
      // TODO: send empty geojson for subboundaries layer
    } else {
      // NOTE: in regions.js setChunks is explicitly called when the child geom
      // already exists... in this case I don't think we need to

      // fetch geom from children.json if needed
      if(!has(children, currentStyle)) {
        await dispatch(fetchBoundaryChildren(placePath, currentStyle))
      }
    }

    // update redux store
    dispatch({
      type: UPDATE_BOUNDARY_STYLE,
      data: {
        placePath: placePath,
        style:     currentStyle,
      },
    })
  }

export const getBoundaries = (state, placePath) => {
  return state.boundaries[placePath] || {}
}

export const getBoundaryTypes = (state, placePath) => {
  if(state.boundaries[placePath]) {
    return state.boundaries[placePath].types || []
  }
  return []
}

export const getBoundaryParent = (state, placePath) => {
  if(state.boundaries[placePath]) {
    return state.boundaries[placePath].parent
  }
  return null
}

export const getBoundaryParents = (state, placePath) => {
  if(state.boundaries[placePath]) {
    // Sort the parents in geographical order
    return (
      state.boundaries[placePath].parents?.sort((a, b) => {
        return a.path.split('/').length - b.path.split('/').length
      }) || []
    )
  }

  return []
}

export const getBoundaryOutline = (state, placePath) => {
  if(placePath) {
    if(state.boundaries[placePath] && !state.boundaries[placePath].isLoading) {
      return state.boundaries[placePath].outline
    }
  }
  return null
}

export const getPlaceParcelPath = (state, placePath) => {
  return state.boundaries?.[placePath]?.parcel_path
}

export const getAllParcelPaths = (state) => {
  const boundaryKeys = Object.keys(state.boundaries)
  return boundaryKeys.map((boundary) => {
    return state.boundaries[boundary]?.parcel_path
  })
}

export const getWorkingBoundaryName = (state, placePath) => {
  // workingBoundary is the boundary used for Project Home, Filter, Exports, etc.
  return state.boundaries?.[placePath]?.outline?.properties?.headline
}

export const getBoundaryStyle = (state, placePath) => {
  return state.boundaries?.[placePath]?.style
}

export const getChildren = (state, placePath, key) => {
  if(state.boundaries[placePath] && state.boundaries[placePath].children) {
    const boundaryTypes = getBoundaryTypes(state, placePath)
    const defaultBoundaries = getDefaultBoundaryType(boundaryTypes)

    // We either are told what boundaries to display (by `key`), or we
    // need to show the best default
    let keyToTry = key
    if(!key) {
      keyToTry = defaultBoundaries.key
    }
    const bounds = state.boundaries[placePath].children[keyToTry]
    if(bounds) {
      return bounds
    }
  }

  // Fallback in case we got nothing
  return {
    geojson: {
      type:     'FeatureCollection',
      features: [],
    },
  }
}

export const getWorkingBoundaryPath = (state) => {
  // workingBoundary is the boundary used for Project Home, Filter, Exports, etc.
  return state.boundaries.workingBoundary
}

export const getActivePlaceParcelPath = (state) => {
  const workingBoundaryPath = getWorkingBoundaryPath(state)

  return getPlaceParcelPath(state, workingBoundaryPath)
}

export const getWorkingBoundaryStoreLink = (state) => {
  const workingBoundaryPath = getWorkingBoundaryPath(state)
  const storeLink = state.boundaries?.[workingBoundaryPath]?.store_link
  return storeLink || null
}

export const getWorkingBoundaryPlaceOnline = (state) => {
  // checks if the working boundary place is online (has data).
  // If not, we render a message and request form.
  // show_form: true means the place is NOT online.
  const workingBoundaryPath = getWorkingBoundaryPath(state)
  if(!workingBoundaryPath || !state.boundaries?.[workingBoundaryPath]) {
    return null // Return null if the working boundary does not exist
  }
  return !state.boundaries?.[workingBoundaryPath]?.show_form
}

export const getUnavailablePlaceProps = (state) => {
  // used to populate the PlaceUnavailableView component form
  // parallels _data_request.html.erb
  const path = getWorkingBoundaryPath(state) || null
  const name = state.boundaries?.[path]?.name || null
  const csrfToken = state.boundaries?.[path]?.csrf_token || null
  const email = state.boundaries?.[path]?.email || null
  return {
    path:      path,
    name:      name,           // username
    csrfToken: csrfToken,
    email:     email,         // email address or phone number
  }
}

const bounds = (
  state = {
    types:     [],
    outline:   {}, // TODO
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_BOUNDARIES:
      return {
        ...state,
        ...action.data,
        isLoading: false,
      }
    case REQUEST_BOUNDARIES:
      return {
        ...state,
        isLoading: true,
      }
    default:
      return state
  }
}

export const updateCurrentMapBoundary = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_CURRENT_MAP_BOUNDARY,
    data
  })
}

export const getCurrentMapBoundaryPath = (state) => {
  // currentMapBoundary is the boundary that matches the current map viewport
  return state.boundaries.currentMapBoundary.path
}

export const getCurrentMapBoundaryName = (state) => {
  // currentMapBoundary is the boundary that matches the current map viewport
  return state.boundaries.currentMapBoundary.name
}

// Reducers
const initialState = {
  workingBoundary:    null, // path. workingBoundary is the boundary used for Project Home, Filter, Export, etc.
  currentMapBoundary: {}, //object with path and name. currentMapBoundary is the boundary that matches the current map viewport
}

/**
 * Store places by their path
 */
export const boundaries = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BOUNDARIES:
    case REQUEST_BOUNDARIES:
      return {
        ...state,
        [action.placePath]: bounds(state[action.placePath], action),
      }
    case UPDATE_BOUNDARY_STYLE:
      return {
        ...state,
        [action.data.placePath]: {
          ...state[action.data.placePath],
          style: action.data.style,
        },
      }
    case UPDATE_BOUNDARY_CHILDREN:
      return {
        ...state,
        [action.placePath]: {
          ...state[action.placePath],
          children: {
            ...state[action.placePath].children,
            [action.style]: action.data,
          },
        },
      }
    case UPDATE_WORKING_BOUNDARY:
      return {
        ...state,
        workingBoundary: action.placePath
      }
    case UPDATE_CURRENT_MAP_BOUNDARY:
      return {
        ...state,
        currentMapBoundary: action.data
      }
    default:
      return state
  }
}

export default boundaries
