import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUnavailablePlaceProps } from 'ducks/boundaries'

const PlaceUnavailableView = () => {
  const [requested, setRequested] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const path = useSelector((state) => state.boundaries.workingBoundary)
  // camelcase disabled for rails form props
  // eslint-disable-next-line camelcase
  const { csrf_token, propName, propEmail } = useSelector(getUnavailablePlaceProps)

  useEffect(() => {
    if(propName && name === '') setName(propName)
    if(propEmail && email === '') setEmail(propEmail)
  }, [path, name, email, propName, propEmail])

  const handleSubmit = () => {
    setRequested(true)
  }

  return (
    <div id="insights-place-unavailable" className="flex-col-start">
      <div className="row width-100 sticky-header">
        <div className="col-sm-12">
          <h3>Place Unavailable</h3>
          <p className="small">
            <i className="fa fa-exclamation-circle margin-right-md"></i>
            No parcel data available for this place. We&apos;ll be sure to update
            if/when data becomes available here.
          </p>
          <p className="hr"></p>
        </div>
      </div>
      <div className="row width-100">
        <div className="col-sm-12 col-md-9">
          <div className="request">
            <h4>Want to see this place online?</h4>

            {requested ? (
              <p>Thank you for your suggestion!</p>
            ) : (
              <>
                <p style={{ maxWidth: '600px' }}>
                  We need your help to put every parcel in the country online. Let
                  us know you&apos;re interested and we&apos;ll add this location to our list.
                </p>

                <form
                  action="/requests"
                  method="post"
                  className="data-request-form"
                  onSubmit={handleSubmit}
                  style={{ maxWidth: '600px' }}
                >
                  <input type="hidden" name="path" value={path} />
                  <input
                    type="hidden"
                    name="authenticity_token"
                    // eslint-disable-next-line camelcase
                    value={csrf_token}
                  />
                  <input type="hidden" name="data_report" value="false" />
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact">Email address or phone number</label>
                    <input
                      type="text"
                      id="contact"
                      name="contact"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email or phone number"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="comment">Reason for interest</label>
                    <textarea
                      id="comment"
                      name="comment"
                      className="form-control"
                      placeholder="Tell us about why you're interested in this place!"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <input type="submit" className="btn btn-primary submit" value="Submit" />
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlaceUnavailableView