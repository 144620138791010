/* eslint-disable camelcase */ // ignore activity_counts from rails response
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getCurrentPane } from 'ducks/mapProperties'
import { getWorkingBoundaryPlaceOnline, getWorkingBoundaryStoreLink } from 'ducks/boundaries'
import { activeViewLabels, activeViewParams, viewAccessRules } from './insightsSettings'
import { has } from 'lodash'
import withStore from '../withStore'
import withFlight from '../../containers/withFlight'

// Utility Components
import WorkingBoundaryBreadcrumbs from '../Map/MapControls/WorkingBoundaryBreadcrumbs'
import ReactTooltip from 'react-tooltip'

// View Components
import VacancyView from './VacancyView'
import LandUseView from './LandUseView'
import OwnershipView from './OwnershipView'
import GeneralView from './GeneralView'
import PlaceUnavailableView from './PlaceUnavailableView'

// Locale data yaml loaded by Webpack on build, used for locale strings
import localeDataYaml from '../../../../config/locales/data.en.yml'

const InsightsPane = ({ passEventToFlight }) => {
  const [paneIsLoading, setPaneIsLoading] = useState(true)
  const [activeView, setActiveView] = useState(null)
  const [placeUnavailable, setPlaceUnavailable] = useState(false)
  const [lbcsData, setLbcsData] = useState([])
  const [premiumData, setPremiumData] = useState({})
  const [ownerData, setOwnerData] = useState([])
  const [nativeLandData, setNativeLandData] = useState([])
  const [placeInfo, setPlaceInfo] = useState({})
  const [numericData, setNumericData] = useState({})
  const [wikiData, setWikiData] = useState({})

  const workingBoundaryPath = useSelector(
    (state) => state.boundaries.workingBoundary
  )
  const visiblePane = useSelector(getCurrentPane)
  const dataStoreLink = useSelector(getWorkingBoundaryStoreLink)
  const placeIsOnline = useSelector(getWorkingBoundaryPlaceOnline)
  const isPaidUser = window.data.has_sitecontrol ?? false // isPaidUser() caused load error
  const isCountryOrWorld = workingBoundaryPath ? isPathCountryOrWorld(workingBoundaryPath) : false
  const isStateOrProvince = workingBoundaryPath ? isPathStateOrProvince(workingBoundaryPath) : false
  const isUsa = workingBoundaryPath ? isPathUSA(workingBoundaryPath) : false

  const setData = (data) => {
    Object.keys(data).forEach((key) => {
      switch (key) {
        case 'lbcs':
          setLbcsData(data[key])
          break
        case 'premium':
          setPremiumData(data[key])
          break
        case 'owner':
          setOwnerData(data[key])
          break
        case 'nativeLand':
          setNativeLandData(data[key])
          break
        case 'placeInfo':
          setPlaceInfo(data[key])
          break
        case 'numeric':
          setNumericData(data[key])
          break
        case 'wikipedia':
          setWikiData(data[key])
          break
        default:
          break
      }
    })
  }

  // Flags to determine view access
  const accessFlags = {
    isPaidUser:           isPaidUser,
    isUSA:                isUsa,
    isStateOrProvince:    isStateOrProvince,
    isCountryOrWorld:     isCountryOrWorld,
  }

  // Utility function to determine if a view should be allowed
  function isViewAllowed(rules, flags) {
    return (
      (!rules.requiresPaid || flags.isPaidUser) &&
      (!rules.requiresUSA || flags.isUSA) &&
      (!rules.excludesStateOrProvince || !flags.isStateOrProvince) &&
      (!rules.excludesCountryOrWorld || !flags.isCountryOrWorld)
    )
  }

  // Compute allowed views based on access rules and flags
  const allowedViews = Object.keys(viewAccessRules).reduce((acc, viewKey) => {
    acc[viewKey] = isViewAllowed(viewAccessRules[viewKey], accessFlags)
    return acc
  }, {})

  const fetchInsightsData = useCallback(
    async (view) => {
      if(!workingBoundaryPath || visiblePane !== '#insights') return
      try {
        const viewKey = Object.keys(activeViewLabels).find(
          (key) => activeViewLabels[key] === view // 'General Info' => 'general'
        )
        const params = activeViewParams[viewKey]
          .map((param) => `insights[]=${param}`)
          .join('&')
        // debug(`Fetching insights data for: ${workingBoundaryPath} with params: ${params}`)
        const response = await fetch(
          `${workingBoundaryPath}/insights.json?${params}`
        )
        const data = await response.json()
        if(_.has(data, 'insights')) {
          // debug('Fetched insights data:', data)
          setData(data.insights)
          setPlaceUnavailable(false)
          setPaneIsLoading(false)
        }
      } catch (error) {
        debug('Error fetching insights data:', error)
        setPaneIsLoading(false)
      }
    },
    [workingBoundaryPath, visiblePane]
  )

  useEffect(() => {
    if(visiblePane !== '#insights') return

    if(!placeIsOnline) {
      // debug('Place is not online, skipping data fetch')
      setPlaceUnavailable(true)
      setActiveView('Place Not Available')
      setPaneIsLoading(false
      )
    } else {
      setPlaceUnavailable(false)
      setPaneIsLoading(true)

      // Check if the active view is a chart view, e.g. Land Use & Zoning, Vacancy, Ownership
      const limitedView = activeView !== activeViewLabels?.general || activeView !== 'Place Not Available'
      // Check if the working boundary path is a world, country, or state/province
      const limitedAdminLevel = isCountryOrWorld || isStateOrProvince

      // User should not be able to access a limited view for a world, country, or state/province
      if(limitedView && limitedAdminLevel) {
        setActiveView(activeViewLabels.general)
        fetchInsightsData(activeViewLabels.general)
      } else if(!activeView || activeView === 'Place Not Available') {
        setActiveView(activeViewLabels.general)
        fetchInsightsData(activeViewLabels.general)
      } else {
        fetchInsightsData(activeView)
      }
    }
  }, [
    workingBoundaryPath,
    visiblePane,
    placeIsOnline,
    activeView,
    isCountryOrWorld,
    isStateOrProvince,
    fetchInsightsData,
  ])

  const combineLbcsData = (data) => {
    if(!data) return null
    const categories = {
      unknown:        0,
      residential:    0,
      trade:          0,
      industrial:     0,
      infrastructure: 0,
      travel:         0,
      assembly:       0,
      leisure:        0,
      natural:        0,
      vacant:         0,
    }

    data?.forEach((location) => {
      location?.activity_counts?.forEach((activity) => {
        const { activity_type, count } = activity
        if(has(categories, activity_type)) {
          categories[activity_type] += count
        } else {
          categories['unknown'] += count
        }
      })
    })
    return categories
  }

  const localeStrings = localeDataYaml?.en?.stats?.common
  const vacancyData = premiumData?.charts?.usps
  const topOwnerData = ownerData

  if(visiblePane !== '#insights') {
    return null
  }

  const combinedLbcsData =
    lbcsData?.length > 0 ? combineLbcsData(lbcsData) : null

  const renderActiveView = () => {
    switch (activeView) {
      case 'Land Use & Zoning':
        return (
          <LandUseView
            lbcsData={lbcsData}
            combinedLbcsData={combinedLbcsData}
            isLoading={paneIsLoading}
          />
        )
      case 'Vacancy':
        return (
          <VacancyView
            vacancyData={vacancyData}
            isLoading={paneIsLoading}
          />
        )
      case 'Ownership':
        return (
          <OwnershipView
            topOwnerData={topOwnerData}
            isLoading={paneIsLoading}
            passEventToFlight={passEventToFlight}
          />
        )
      case 'Place Not Available':
        return <PlaceUnavailableView />
      case 'General Info':
      default:
        // Default to General Info view
        return (
          <GeneralView
            placeInfo={placeInfo}
            numericData={numericData}
            nativeLandData={nativeLandData}
            wikiData={wikiData}
            workingBoundaryPath={workingBoundaryPath}
            visiblePane={visiblePane}
            isLoading={paneIsLoading}
            storeLink={dataStoreLink}
            isUsa={isUsa}
            localeStrings={localeStrings}
          />
        )
    }
  }

  return (
    <>
      <div id="nav-insights-container" className="height-100">
        <p style={{ marginBottom: '0px' }}>Working Boundary</p>
        <div style={{ margin: '2px 0px 10px' }}>
          <h3 className="place-name margin-none">
            {placeInfo?.headline ?? 'Insights'}
          </h3>
        </div>
        <WorkingBoundaryBreadcrumbs />
        <ul
          className="nav nav-pills nav-stacked margin-top-lg"
          id="nav-insights"
        >
          {Object.keys(activeViewLabels).map((viewKey) => (
            <li
              key={viewKey}
              className={activeView === activeViewLabels[viewKey] ? 'active bold' : ''}
            >
              <a
                href="#"
                onClick={(e) => {
                  if(!allowedViews[viewKey] || placeUnavailable) {
                    e.preventDefault() // Prevent interaction, but allow tooltip
                  } else {
                    setActiveView(activeViewLabels[viewKey])
                  }
                }}
                className={
                  (activeView === activeViewLabels[viewKey] ? 'plan-color-accent' : '') +
                  (!allowedViews[viewKey] || placeUnavailable ? ' disabled-product' : '')
                }
                {...(viewKey === 'ownership' && isStateOrProvince
                  ? { 'data-tip': 'Ownership', 'data-for': 'nav-insights-tt' }
                  : {})}
              >
                {activeViewLabels[viewKey]}
              </a>
            </li>
          ))}
        </ul>

        {!isPaidUser && (
          <ReactTooltip
            id="nav-insights-tt"
            place="top"
            className="tooltip upsell"
            effect="solid"
            clickable={true}
            delayHide={500}
            delayShow={250}
            delayUpdate={500}
            getContent={(dataTip) => (
              <div className="tooltip-inner margin-btm-md">
                <p>{`${dataTip} insights are available to Regrid Pro customers.`}</p>
                <a href="/plans">
                  <i className="fas fa-arrow-right" />
                  {' '}Upgrade to Pro
                </a>
              </div>
            )}
          />
        )}
        {isStateOrProvince && (
          <ReactTooltip
            id="nav-insights-tt"
            place="top"
            className="tooltip upsell"
            effect="solid"
            clickable={true}
            delayHide={500}
            delayShow={250}
            delayUpdate={500}
            getContent={(dataTip) => (
              <div className="tooltip-inner margin-btm-md">
                <p>{`${dataTip} insights are not available for this region.`}</p>
              </div>
            )}
          />
        )}

        <div id="nav-insights-mobile">
          <label htmlFor="insights-mobile-select" className="small">
            Current Insights Tab
          </label>
          <select
            id="insights-mobile-select"
            className="form-control"
            value={activeView ?? activeViewLabels.general}
            onChange={(e) => setActiveView(e.target.value)}
            disabled={placeUnavailable}
          >
            {Object.keys(activeViewLabels).map((viewKey) => (
              <option
                key={viewKey}
                value={activeViewLabels[viewKey]}
                disabled={!allowedViews[viewKey] || placeUnavailable}
              >
                {activeViewLabels[viewKey]}
              </option>
            ))}
          </select>
        </div>
        <p className="hr md-only"></p>
      </div>
      <div className="height-100" id="insights-content">
        {renderActiveView()}
      </div>
    </>
  )
}

InsightsPane.propTypes = {
  passEventToFlight: PropTypes.func.isRequired,
}

export default withStore(withFlight(InsightsPane))