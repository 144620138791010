import React from 'react'
import PropTypes from 'prop-types'
import NativeLandCard from './NativeLandCard'
import WikipediaCard from './WikipediaCard'
import AskChatGptCard from './AskChatGptCard'
import FallbackLoadingView from './FallbackLoadingView' // Fallback Loading View
import { formatLocaleStats } from './insightsUtils'

// GeneralView Component
const GeneralView = ({
  placeInfo,
  numericData,
  nativeLandData,
  wikiData,
  workingBoundaryPath,
  visiblePane,
  isLoading,
  storeLink,
  isUsa,
  localeStrings,
}) => {
  const hasNativeLandData = nativeLandData && nativeLandData.length > 0
  const hasNumericData = numericData && Object.keys(numericData)?.length > 0

  // Fallback or Loading State
  if(isLoading || !placeInfo || !numericData) {
    return (
      <FallbackLoadingView id="insights-general-info" title="General Info" isLoading={isLoading}>
        <p className="small">
          Summary statistics for your currently selected place. Click on the other tabs to see breakdowns of specific property characteristics
          in this area, like ownership or land use.
        </p>
      </FallbackLoadingView>
    )
  }

  const numericDataItems = hasNumericData
    ? formatLocaleStats(numericData, localeStrings)
    : null

  return (
    <div id="insights-general-info" className="flex-col-start">
      <div className="row width-100 sticky-header">
        <div className="col-sm-12">
          <h3>General Info</h3>
          <div className="flex-row-start">
            <p className="small">
              Summary statistics for your currently selected place. Click on the
              other tabs to see breakdowns of specific property characteristics
              in this area, like ownership or land use.
            </p>
          </div>
        </div>
        <div className="col-sm-12">
          <p className="hr"></p>
        </div>
      </div>
      <div className="flex-row-start width-100">
        <div className="row width-100">
          <div className="col-md-6">
            <div id="general-info" className="panel panel-default plan-color">
              <div className="panel-heading">
                {placeInfo && placeInfo?.headline ? (
                  <h3 className="panel-title margin-btm-sm">
                    {`Highlights for ${placeInfo.headline}`}
                  </h3>
                ) : (
                  <h3 className="panel-title">Place Highlights</h3>
                )}
              </div>
              <div className="panel-body">
                {numericDataItems &&
                  numericDataItems.map((item, index) => (
                    <p key={index}>
                      <span className="bold lead">{item.value}</span>{' '}
                      <span>{item.headline}</span>
                    </p>
                  ))}
              </div>
            </div>
            {wikiData && (
              <div id="insights-wikipedia" className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title">From Wikipedia.org</h3>
                </div>
                <div className="panel-body">
                  <WikipediaCard
                    wikiData={wikiData}
                    visiblePane={visiblePane}
                    headline={placeInfo?.headline}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6">
            {storeLink && (
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title">Buy Data Files</h3>
                </div>
                <div className="panel-body">
                  {isUsa
                    ? <p>Want to purchase individual county files or license entire states? Visit our Data Store to learn more.</p>
                    : <p>Want to license data files for an entire province or territory? Visit our Data Store to learn more.</p>
                  }
                  <p>
                    <a
                      role="button"
                      href={storeLink}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-success btn-round btn-block"
                      id="insights-data-store"
                    >
                      <i className="fas fa-shopping-cart margin-right-md"></i>
                      Shop Data Store
                    </a>
                  </p>
                </div>
              </div>
            )}
            <div id="insights-ask-gpt" className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Ask A.I.</h3>
              </div>
              <div className="panel-body">
                <AskChatGptCard
                  workingBoundaryPath={workingBoundaryPath}
                  visiblePane={visiblePane}
                />
              </div>
            </div>
            {nativeLandData && hasNativeLandData && (
              <NativeLandCard nativeLandData={nativeLandData} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

GeneralView.propTypes = {
  placeInfo:           PropTypes.object,
  numericData:         PropTypes.object,
  nativeLandData:      PropTypes.array,
  wikiData:            PropTypes.object,
  workingBoundaryPath: PropTypes.string,
  visiblePane:         PropTypes.string,
  isLoading:           PropTypes.bool,
  storeLink:           PropTypes.string,
  isUsa:               PropTypes.bool,
  localeStrings:       PropTypes.object,
}

export default GeneralView