import React from 'react'
import PropTypes from 'prop-types'

const FallbackLoading = ({
  id,
  title,
  children, // for the header description (can include links, etc.)
  isLoading,
  loadingMessage = 'Loading Place Data...',
  errorMessage = 'Oops! We couldn\'t load place data. Please try again.'
}) => (
  <div id={id} className="flex-col-start height-100">
    <div className="row width-100 sticky-header">
      <div className="col-sm-12">
        <h3>{title}</h3>
        {children}
        <p className="hr"></p>
      </div>
    </div>
    {isLoading ? (
      <div className="insights-loading flex-col-center width-100 height-100">
        <i className="fas fa-spinner fa-spin" />
        <h4 className="margin-top-lg">{loadingMessage}</h4>
      </div>
    ) : (
      <div className="insights-failed flex-col-center width-100 height-100">
        <i className="fas fa-exclamation-triangle" />
        <h4 className="margin-top-lg">{errorMessage}</h4>
      </div>
    )}
  </div>
)

FallbackLoading.propTypes = {
  id:             PropTypes.string.isRequired,
  title:          PropTypes.string.isRequired,
  children:       PropTypes.node, // for header description
  isLoading:      PropTypes.bool,
  loadingMessage: PropTypes.string,
  errorMessage:   PropTypes.string,
}

export default FallbackLoading
